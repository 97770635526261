
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MsGroupFilterCard from "@/components/student/MsGroupFilterCard.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import { Getter, State } from "@/store/helper";
import { Options, Vue } from "vue-class-component";
import { getStudentsBasedOnRole } from "../../api/student";
import { School } from "@/entities/school";
import { Classroom } from "@/entities/classroom";
import { getClassRoomsBasedOnRole } from "@/api/classroom";

@Options({
  components: {
    MButton,
    MIcon,
    MSelectBox,
    MTextField,
    MsGroupFilterCard
  },
  emits: ["update"],
  props: {
    defaultStudentIds: Array
  }
})
export default class MsStudentPicker extends Vue {
  defaultStudentIds: string[] = [];
  selectedStudentIds: string[] = [];
  studentSearchKeyword = "";
  searchedStudents: Student[] = [];
  classroomNames: string[] = [];
  groupTitles: string[] = [];
  focusedClassroom = "";
  focusedGroup = "";

  @State("school") school?: School;
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isServiceProvider") isServicePrivider?: boolean;

  get students(): Student[] {
    if (this.isServicePrivider) {
      const schoolDocId = this.school!.ref.id;
      return getStudentsBasedOnRole(
        this.isServicePrivider,
        store.state.students,
        schoolDocId
      );
    }
    return store.state.students;
  }

  get selectedStudents(): Student[] {
    return this.students.filter(student =>
      this.selectedStudentIds.includes(student.ref.id)
    );
  }

  get classrooms(): Classroom[] {
    if (this.isAdmin) {
      return [];
    }
    if (this.isServicePrivider) {
      const schoolDocId: string = this.school!.ref.id;
      return getClassRoomsBasedOnRole(
        this.isServicePrivider,
        store.state.classrooms,
        schoolDocId
      );
    }
    return store.state.classrooms;
  }

  addStudent(id: string) {
    this.selectedStudentIds.push(id);
    this.$emit("update", this.selectedStudents);
  }

  removeStudent(id: string) {
    this.selectedStudentIds = this.selectedStudentIds.filter(
      item => item !== id
    );
    this.$emit("update", this.selectedStudents);
  }

  searchStudents() {
    if (this.studentSearchKeyword === "") {
      this.searchedStudents = [];
      return;
    }
    this.searchedStudents = this.students.filter(
      student =>
        student.data.id.includes(this.studentSearchKeyword) ||
        student.data.name
          .toLowerCase()
          .includes(this.studentSearchKeyword.toLowerCase())
    );
    this.$emit("update", this.selectedStudents);
  }

  addAllStudents() {
    this.selectedStudentIds = this.students.map(s => s.ref.id);
    this.$emit("update", this.selectedStudents);
  }

  removeAllStudents() {
    this.selectedStudentIds = [];
    this.$emit("update", this.selectedStudents);
  }

  addClassroomStudents() {
    const selectedClassroom = this.classrooms.find(
      c => c.data.name === this.focusedClassroom
    );
    if (!selectedClassroom) return;
    const studentsOfClassroom = this.students.filter(
      s => s.ref.parent.parent?.id === selectedClassroom.ref.id
    );
    const next = [
      ...this.selectedStudentIds,
      ...studentsOfClassroom.map(s => s.ref.id)
    ];
    this.selectedStudentIds = next.filter(
      (_, i, self) => self.indexOf(_) === i
    );
    this.focusedClassroom = "";
    this.$emit("update", this.selectedStudents);
  }

  addGroupStudents() {
    const selectedGroup = store.state.groups.find(
      g => g.data.title === this.focusedGroup
    );
    if (!selectedGroup) return;
    const studentsOfGroup = selectedGroup.data.students
      .map(ref => this.students.find(s => s.ref.id === ref.id))
      .filter(_ => _);
    const next = [
      ...this.selectedStudentIds,
      ...studentsOfGroup.map(s => s?.ref.id ?? "")
    ];
    this.selectedStudentIds = next.filter(
      (_, i, self) => self.indexOf(_) === i
    );
    this.focusedGroup = "";
    this.$emit("update", this.selectedStudents);
  }

  created() {
    if (this.defaultStudentIds.length > 0) {
      this.selectedStudentIds = this.defaultStudentIds;
    }
    if (this.classrooms) {
      this.classroomNames = this.classrooms.map(c => c.data.name);
    }
    if (store.state.groups) {
      this.groupTitles = store.state.groups.map(g => g.data.title);
    }
  }
}
