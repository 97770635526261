
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["change"],
  props: {
    label: String,
    name: String,
    placeholder: String,
    options: Array,
    value: [Object, String],
    valueKey: String,
    textKey: String,
    key: String
  }
})
export default class MSelectBox extends Vue {
  label = "";
  name = "";
  placeholder = "";
  options = [];
  value: any;
  valueKey = "";
  textKey = "";
  key = "";

  valueOf(option: any) {
    if (this.valueKey.length === 0) {
      return option;
    }
    const keys = this.valueKey.split(".");
    let ret = option;
    for (const key of keys) {
      ret = ret[key];
    }
    return ret;
  }

  textOf(option: any) {
    if (this.textKey.length === 0) {
      return option;
    }
    const keys = this.textKey.split(".");
    let ret = option;
    for (const key of keys) {
      ret = ret[key];
    }
    return ret;
  }

  keyOf(option: any) {
    if (this.key.length === 0) {
      return option;
    }
    const keys = this.key.split(".");
    let ret = option;
    for (const key of keys) {
      ret = ret[key];
    }
    return ret;
  }
}
