
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Options, Vue } from "vue-class-component";
import { FilterType, GroupFilter } from "@/entities/group";
import { CustomProperty } from "@/entities/custom_property";

type TargetKey =
  | "grade"
  | "classSchedules"
  | "totalDayCount"
  | "created"
  | "name";

type FilterTarget = {
  key: TargetKey;
  text: string;
};

type FilterTypeAndText = {
  type: FilterType;
  text: string;
};

type ValueType = "number" | "select" | "date" | "string";

@Options({
  components: {
    MButton,
    MIcon,
    MSelectBox,
    MTextField
  },
  emits: ["remove", "changeKey", "changeType", "changeValue"],
  props: {
    filter: Object,
    customProperties: Array
  }
})
export default class MsGroupFilterCard extends Vue {
  filter!: GroupFilter;
  customProperties: CustomProperty[] = [];
  targets: FilterTarget[] = [
    {
      key: "grade",
      text: "学年"
    },
    {
      key: "classSchedules",
      text: "授業日時"
    },
    {
      key: "totalDayCount",
      text: "累計学習日数"
    },
    {
      key: "created",
      text: "登録日時"
    },
    {
      key: "name",
      text: "生徒名"
    }
  ];
  baseTypes: FilterTypeAndText[] = [
    {
      type: "in",
      text: "次の値を含む"
    },
    {
      type: "not-in",
      text: "次の値を含まない"
    },
    {
      type: "==",
      text: "次の値と等しい"
    },
    {
      type: ">=",
      text: "次の値以上"
    },
    {
      type: "<=",
      text: "次の値以下"
    }
  ];

  get filterTypes(): FilterType[] {
    if (this.filter.key === "grade") {
      return ["=="];
    } else if (this.filter.key === "classSchedules") {
      return ["in", "not-in"];
    } else if (this.filter.key === "totalDayCount") {
      return [">=", "<=", "=="];
    } else {
      return [">=", "<=", "==", "in", "not-in"];
    }
  }

  get types(): FilterTypeAndText[] {
    return this.baseTypes.filter(item => this.filterTypes.includes(item.type));
  }

  get valueType(): ValueType {
    if (this.filter.key === "grade") {
      return "select";
    } else if (this.filter.key === "classSchedules") {
      return "select";
    } else if (this.filter.key === "totalDayCount") {
      return "number";
    } else {
      return "string";
    }
  }

  get values(): string[] {
    if (this.filter.key === "grade") {
      return [
        "小1",
        "小2",
        "小3",
        "小4",
        "小5",
        "小6",
        "中1",
        "中2",
        "中3",
        "高1",
        "高2",
        "高3",
        "その他"
      ];
    } else if (this.filter.key === "classSchedules") {
      return ["月曜", "火曜", "水曜", "木曜", "金曜", "土曜", "日曜"];
    } else {
      return [];
    }
  }

  changeKey(key: string) {
    this.$emit("changeKey", key);
  }

  changeType(type: FilterType) {
    this.$emit("changeType", type);
  }

  changeValue(value: string | number) {
    this.$emit("changeValue", value);
  }

  remove() {
    this.$emit("remove");
  }
}
