import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4 flex items-center rounded-md border-2 border-gray-200" }
const _hoisted_2 = { class: "flex-grow flex flex-col" }
const _hoisted_3 = { class: "flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_select_box = _resolveComponent("m-select-box")
  const _component_m_text_field = _resolveComponent("m-text-field")
  const _component_m_icon = _resolveComponent("m-icon")
  const _component_m_button = _resolveComponent("m-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_m_select_box, {
        key: "key",
        name: "key",
        placeholder: "対象を選択",
        options: _ctx.targets,
        value: _ctx.filter.key,
        "value-key": "key",
        "text-key": "text",
        class: "mb-6",
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeKey($event)))
      }, null, 8, ["options", "value"]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_m_select_box, {
          key: "type",
          name: "type",
          placeholder: "条件を選択",
          options: _ctx.types,
          value: _ctx.filter.type,
          "value-key": "type",
          "text-key": "text",
          class: "flex-1 mr-3",
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeType($event)))
        }, null, 8, ["options", "value"]),
        (_ctx.valueType == 'select')
          ? (_openBlock(), _createBlock(_component_m_select_box, {
              key: 0,
              name: "value",
              placeholder: "値を選択",
              options: _ctx.values,
              value: _ctx.filter.value,
              class: "flex-1 ml-3",
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeValue($event)))
            }, null, 8, ["options", "value"]))
          : (_ctx.valueType == 'number')
            ? (_openBlock(), _createBlock(_component_m_text_field, {
                key: 1,
                name: "value",
                type: "number",
                placeholder: "",
                value: _ctx.filter.value,
                class: "flex-1 ml-3",
                onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeValue($event)))
              }, null, 8, ["value"]))
            : (_ctx.valueType == 'date')
              ? (_openBlock(), _createBlock(_component_m_text_field, {
                  key: 2,
                  name: "value",
                  type: "date",
                  placeholder: "",
                  value: _ctx.filter.value,
                  class: "flex-1 ml-3",
                  onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeValue($event)))
                }, null, 8, ["value"]))
              : (_openBlock(), _createBlock(_component_m_text_field, {
                  key: 3,
                  name: "value",
                  type: "text",
                  placeholder: "",
                  value: _ctx.filter.value,
                  class: "flex-1 ml-3",
                  onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeValue($event)))
                }, null, 8, ["value"]))
      ])
    ]),
    _createVNode(_component_m_button, {
      color: "gray-500",
      "ehover-color": "gray-600",
      text: "",
      size: "sm",
      class: "ml-6"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_m_icon, {
          type: "trash",
          size: "5",
          onClick: _ctx.remove
        }, null, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}